import { ApplicationRef, inject, provideAppInitializer } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { META_REDUCERS, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { DataTablesModule } from "angular-datatables";
import { Apollo } from "apollo-angular";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AppComponent } from "./app.component";
import { AppConfig } from "./app.config";
import { APP_RESOLVER_PROVIDERS } from "./app.resolver";
import { ROUTES } from "./app.routes";
import { AppState } from "./app.service";
import { AUTH_GUARD_PROVIDERS } from "./auth.guards";
import { reducers } from "./context/context";
import { Effects } from "./context/effects";
import { getMetaReducers } from "./context/meta.reducers";
import { ENV_PROVIDERS } from "./environment";
import { AuthenticationService } from "./services/auth/authentication.service";
import { BrandService } from "./services/brand/brand.service";
import { DashboardService } from "./services/dashboard/dashboard.service";
import { DataService } from "./services/data/data.service";
import { FormatterService } from "./services/formatter/formatter.service";
import { DataPreparationService } from "./services/data-preparation/data-preparation.service";
import { CustomGraphQLTranslateLoader } from "./services/locale/custom-graphql-translation-loader";
import { LocaleService } from "./services/locale/locale.service";
import { I18nMissingTranslationHandler } from "./services/locale/missingTranslationHandler.service";
import { GlobalErrorHandler } from "./services/logging/global-error-handler";
import { Logger } from "./services/logging/logger";
import { LoggingModule } from "./services/logging/logging.module";
import { LoggingService } from "./services/logging/logging.service";
import { NotificationsService } from "./services/notifications/notifications.service";
import { StartupService } from "./services/startup/startup.service";
import { TemporalService } from "./services/temporal/temporal.service";
import { TooltipService } from "./services/tooltip/tooltip.service";
import { formattedTimeString } from "./util/utils";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { SupportService } from "./services/support/support.service";
import { ExporterService } from './services/exporter/exporter.service';
import { provideHttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "./services/logging/logging.service";
import * as i3 from "./app.config";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/store";
import * as i6 from "./services/logging/logging.module";
import * as i7 from "@ngrx/effects";
import * as i8 from "@ngrx/store-devtools";
import * as i9 from "@ngrx/router-store";
import * as i10 from "@ngx-translate/core";
import * as i11 from "ngx-bootstrap/datepicker";
import * as i12 from "angular-froala-wysiwyg";
/*
 * Platform and Environment providers/directives/pipes
 */
// App is our top level component
// ng2-translate
// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    ...AUTH_GUARD_PROVIDERS,
    AppState,
    AppConfig,
    AuthenticationService,
    DataService,
    DashboardService,
    TooltipService,
    FormatterService,
    DataPreparationService,
    LocaleService,
    BrandService,
    TemporalService,
    StartupService,
    NotificationsService,
    SupportService,
    GlobalErrorHandler,
    ExporterService
];
/* tslint:enable interface-over-type-literal */
/* AoT - If you are using AoT compilation or Ionic 2, you must use an
* exported function instead of an inline function.
*/
export function customTranslateLoader(apollo, logService) {
    return new CustomGraphQLTranslateLoader(apollo, logService);
}
/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
export class AppModule {
    constructor(appRef, appState, loggerService, appConfig) {
        this.appRef = appRef;
        this.appState = appState;
        this.loggerService = loggerService;
        this.appConfig = appConfig;
        this._logger = new Logger("AppModule", loggerService);
        this._logger.info("Instantiated App Module" + formattedTimeString());
    }
}
AppModule.ɵfac = function AppModule_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || AppModule)(i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i1.AppState), i0.ɵɵinject(i2.LoggingService), i0.ɵɵinject(i3.AppConfig)); };
AppModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppModule, bootstrap: [AppComponent] });
AppModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
        ENV_PROVIDERS,
        APP_PROVIDERS,
        {
            provide: MissingTranslationHandler,
            useClass: I18nMissingTranslationHandler
        },
        provideAppInitializer(() => {
            const ss = inject(StartupService);
            return ss.load();
        }),
        {
            provide: META_REDUCERS,
            useFactory: getMetaReducers,
            multi: true
        },
        provideHttpClient(),
        Apollo
    ], imports: [BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(ROUTES, {
            useHash: false,
            preloadingStrategy: PreloadAllModules
            // enableTracing: true
        }),
        StoreModule.forRoot(reducers),
        LoggingModule.forRoot(),
        EffectsModule.forRoot(Effects),
        StoreDevtoolsModule.instrument({
            maxAge: 25 //  Retains last 25 states
        }),
        /**
         * @ngrx/router-store keeps router state up-to-date in the store.
         */
        StoreRouterConnectingModule.forRoot({
            /*
            They stateKey defines the name of the state used by the router-store reducer.
            This matches the key defined in the map of reducers
            */
            stateKey: "router"
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (customTranslateLoader),
                deps: [Apollo, LoggingService]
            }
        }),
        DataTablesModule,
        BsDatepickerModule.forRoot(),
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(), RouterModule] });
