import { TranslateService } from "@ngx-translate/core";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import * as jp from "jsonpath";
import { FormatterService } from "../../services/formatter/formatter.service";
import { Store } from "@ngrx/store";
import * as constants from "../../util/string-constants";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../services/formatter/formatter.service";
import * as i3 from "@ngrx/store";
import * as i4 from "../logging/logging.service";
const LOG_NAMESPACE = "services.data-preparation";
export class DataPreparationService {
    constructor(_translateService, _formatterService, _store, _loggingService) {
        this._translateService = _translateService;
        this._formatterService = _formatterService;
        this._store = _store;
        this._loggingService = _loggingService;
        this.replacements = {};
        this.dataPreparers = {
            dataPreparer1: (config, data) => {
                // For multiple items (like locations) having their multiple rows.
                // Supports Total.
                const rowsMeta = [];
                const primaryItems = [];
                if (null != config.primaryItemsSet) {
                    config.primaryItemsSet.forEach((primaryItemsObj) => {
                        let items = jp.query(data, primaryItemsObj.jsonpath);
                        items.forEach((item) => {
                            item.ddcMeta = {};
                            item.ddcMeta.fixedAdditionalValue = primaryItemsObj.fixedAdditionalValue;
                            item.ddcMeta.styleSelectors = primaryItemsObj.styleSelectors;
                            item.ddcMeta.nullValues = primaryItemsObj.nullValues;
                        });
                        items = this._sortRows(primaryItemsObj.sort, items);
                        primaryItems.push(...items);
                    });
                }
                else {
                    config.primaryItemsJsonpaths.forEach((primaryItemsJsonpath) => {
                        const items = jp.query(data, primaryItemsJsonpath);
                        primaryItems.push(...items);
                    });
                }
                const formattedData = [];
                const unFormattedData = [];
                let rowIdx = -1;
                primaryItems.forEach((primaryItem) => {
                    if (true === this._checkNullValues(primaryItem.ddcMeta.nullValues, primaryItem)) {
                        return;
                    }
                    config.rowsColumns.forEach((rowColumns) => {
                        rowIdx++;
                        const row = [];
                        const rowStyleSelectorValues = [];
                        const unFormattedRow = [];
                        const columns = rowColumns.columns || rowColumns;
                        columns.forEach((rowColumn) => {
                            let value;
                            if (null != rowColumn.jsonpaths) {
                                rowColumn.jsonpaths.find((jsonpath) => {
                                    value = jp.query(primaryItem, jsonpath)[0];
                                    return value;
                                });
                            }
                            if (rowColumn.dynamicValues) {
                                rowColumn.dynamicValues.find((dynamicValue) => {
                                    if (dynamicValue.jsonpath) {
                                        value = jp.query(primaryItem, dynamicValue.jsonpath)[0];
                                    }
                                    else if (dynamicValue.value) {
                                        value = this._translateService.instant(dynamicValue.value);
                                    }
                                    if (null != dynamicValue.replacements) {
                                        Object.entries(dynamicValue.replacements).forEach(([str, path]) => {
                                            if (typeof (path) === "string") {
                                                const replacementValue = jp.query(primaryItem, path)[0];
                                                value = value.replace(str, replacementValue);
                                            }
                                            else {
                                                let replacementValue = jp.query(primaryItem, path.path)[0];
                                                replacementValue = this._applyFormatters(path.formatters, replacementValue);
                                                value = value.replace(str, replacementValue);
                                            }
                                        });
                                    }
                                    value = this._applyFormatters(dynamicValue.formatters, value, primaryItem);
                                    return value;
                                });
                            }
                            else if (null == value && null != rowColumn.value) {
                                value = this._translateService.instant(rowColumn.value);
                                value = value.replace("${row-index}", rowIdx);
                                if (null != rowColumn.valueFormatters) {
                                    value = this._applyFormatters(rowColumn.valueFormatters, value);
                                }
                            }
                            if (null != rowColumn.unFormattedJsonpath) {
                                const unFormattedValue = jp.query(primaryItem, rowColumn.unFormattedJsonpath)[0];
                                unFormattedRow.push(unFormattedValue);
                            }
                            else {
                                unFormattedRow.push(value);
                            }
                            if (null != rowColumn.format) {
                                const formatter = this._formatterService.formatter(rowColumn.format);
                                value = formatter.formatData(value);
                            }
                            if (null != rowColumn.formatters) {
                                value = this._applyFormatters(rowColumn.formatters, value);
                            }
                            let styleSelectorValue;
                            const styleSelectorJsonPaths = rowColumn["style-selector-json-paths"];
                            if (null != styleSelectorJsonPaths) {
                                for (let i = 0; i < styleSelectorJsonPaths.length; i++) {
                                    styleSelectorValue = jp.query(primaryItem, styleSelectorJsonPaths[i])[0];
                                    if (null != styleSelectorValue) {
                                        break;
                                    }
                                }
                            }
                            else if (null != rowColumn["style-selector-json-path"]) {
                                styleSelectorValue = jp.query(primaryItem, rowColumn["style-selector-json-path"])[0];
                            }
                            else {
                                styleSelectorValue = value;
                            }
                            row.push(value);
                            rowStyleSelectorValues.push(styleSelectorValue);
                        });
                        rowsMeta.push({ ...rowColumns.meta, columns, primaryItem, styleSelectorValues: rowStyleSelectorValues });
                        formattedData.push(row);
                        unFormattedData.push(unFormattedRow);
                    });
                });
                return [rowsMeta, formattedData, unFormattedData];
            },
            evalPaths: (config, data) => {
                config.evalPaths.forEach((evalPath) => {
                    jp.apply(data, evalPath.jsonpath, (value) => {
                        if (Array.isArray(value)) {
                            value = JSON.stringify(value);
                        }
                        const toEval = evalPath.eval.replace(/\$\{value\}/g, value);
                        value = window.eval(toEval);
                        return value;
                    });
                });
                return data;
            },
            nonDimensionalTimeseriesDataWithMeta: (config, incomingData) => {
                let data = [];
                const xAxisData = jp.query(incomingData, config.xAxis["list-path"]).map((d) => {
                    let xAxisDatum = jp.query(d, config.xAxis.path)[0];
                    if (null != config.xAxis.formatter) {
                        const formatter = this._formatterService.formatter(config.xAxis.formatter);
                        xAxisDatum = formatter.formatData(xAxisDatum);
                    }
                    return xAxisDatum;
                });
                config.keyValues.forEach((keyValue, keyValueIndex) => {
                    const values = jp.query(incomingData, keyValue.path);
                    if (null != keyValue.eval) {
                        values.forEach((value, valueIdx) => {
                            values[valueIdx] = window.eval(keyValue.eval.replace("${value}", value));
                        });
                    }
                    const datum = {
                        key: this._translateService.instant(keyValue.name),
                        yAxis: keyValue.axis || 1,
                        type: keyValue.type || "bar",
                        color: keyValue.color,
                        values: values.map((value, valueIdx) => {
                            return {
                                valueIndex: keyValueIndex,
                                x: true === config.xAxis["use-index"] ? valueIdx : xAxisData[valueIdx],
                                y: value
                            };
                        })
                    };
                    data.push(datum);
                });
                return data;
            }
        };
        this._logger = new Logger(LOG_NAMESPACE, _loggingService);
        this.subscribeToReferenceContext();
    }
    subscribeToReferenceContext() {
        if (null != this._referenceSubscription$) {
            return;
        }
        this._referenceSubscription$ = this._store.select(constants.CONTEXT_REFERENCE).subscribe((newReference) => {
            this._referenceContext = newReference;
        });
    }
    prepare(config, data) {
        const dataPreparer = this.dataPreparers[config.name];
        if (null == dataPreparer) {
            this._logger.error(`dataPreparer with name '${config.name}' not found.`);
        }
        this._generateReplacementsMap(config.replacements, data);
        const preparerConfig = JSON.parse(JSON.stringify(config));
        if (preparerConfig.primaryItemsSet) {
            preparerConfig.primaryItemsSet.forEach((primaryItemsObj, primaryItemsObjIdx) => {
                preparerConfig.primaryItemsSet[primaryItemsObjIdx].jsonpath = this._applyReplacements(primaryItemsObj.jsonpath);
            });
        }
        return dataPreparer(preparerConfig, data);
    }
    _sortRows(sort, rows) {
        if (null == sort) {
            return rows;
        }
        const sortedRows = [];
        if (null != sort.order) {
            sort.order.forEach((sortValue) => {
                const row = rows.find((_row, i) => {
                    const value = jp.query(_row, sort.key)[0];
                    // tslint:disable-next-line:triple-equals
                    if (value == sortValue) {
                        rows.splice(i, 1);
                        return true;
                    }
                });
                if (null != row) {
                    sortedRows.push(row);
                }
            });
            return sortedRows;
        }
    }
    _checkNullValues(nullValues, data) {
        if (null != nullValues && true === nullValues.hide) {
            const values = [];
            for (const path of nullValues.valuePaths) {
                const dataPath = path;
                const value = jp.query(data, dataPath)[0];
                values.push(value);
            }
            if (nullValues.rule === "All") {
                if (true === values.every(x => x == null)) {
                    return true;
                }
            }
            else {
                if (true === values.some(x => x == null)) {
                    return true;
                }
            }
        }
        return false;
    }
    _generateReplacementsMap(replacementsConfig, data) {
        this.replacements = {};
        if (null != replacementsConfig) {
            replacementsConfig.forEach((replacementObj) => {
                const datum = jp.query(data, replacementObj.path);
                if (datum.length > 0) {
                    this.replacements = { ...this.replacements, ...replacementObj.exists };
                    return;
                }
                this.replacements = { ...this.replacements, ...replacementObj.default };
            });
        }
    }
    _applyReplacements(path) {
        Object.entries(this.replacements).forEach((replacement) => {
            path = path.replace(`\${${replacement[0]}}`, replacement[1]);
        });
        return path;
    }
    _getReplacementValue(value, config, data = null) {
        let sourceData = data;
        if (null == config.key) {
            return value;
        }
        if (config.context === constants.CONTEXT_REFERENCE) {
            sourceData = this._referenceContext;
        }
        return jp.query(sourceData, config.path)[0];
    }
    _applyFormatters(formatters, value, data = null) {
        if (null == formatters) {
            return value;
        }
        formatters.forEach((formatterConfig) => {
            const formatter = this._formatterService.formatter(formatterConfig.formatter);
            let replacementValue = this._getReplacementValue(value, formatterConfig, data);
            replacementValue = formatter.formatData(replacementValue);
            if (formatterConfig.key) {
                value = value.replace(formatterConfig.key, replacementValue);
            }
            else {
                value = replacementValue;
            }
        });
        return value;
    }
}
DataPreparationService.ɵfac = function DataPreparationService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || DataPreparationService)(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.FormatterService), i0.ɵɵinject(i3.Store), i0.ɵɵinject(i4.LoggingService)); };
DataPreparationService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DataPreparationService, factory: DataPreparationService.ɵfac });
