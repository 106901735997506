import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { UserLogoutInitiated, UserReset } from "../../context/user.actions";
import { copyToClipboard } from "../../util/utils";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import { NotificationsService } from "../notifications/notifications.service";
import { AUTH_PICTURE, AUTH_USER_NAME, AUTHENTICATION_LOG_NAMESPACE } from "./spi/auth-spi.interface";
// String constants
import { first, firstValueFrom } from "rxjs";
import { HEADER_BEARER, LS_USER } from "../../util/string-constants";
import { Auth0AuthenticationServiceProvider, PROVIDER_AUTH0 } from "./spi/auth0-spi.service";
import { EntraAuthenticationServiceProvider, PROVIDER_ENTRA } from "./spi/entra-spi.service";
import * as i0 from "@angular/core";
import * as i1 from "../logging/logging.service";
import * as i2 from "@ngrx/store";
import * as i3 from "../notifications/notifications.service";
import * as i4 from "@ngx-translate/core";
const _TRANSLATE_UX_PLACEHOLDERS_PFX = "UX.PLACEHOLDERS.";
const _TRANSLATE_LOGOUT_OPTIONS_PFX = _TRANSLATE_UX_PLACEHOLDERS_PFX + "LOGOUT-OPTIONS.";
const _TRANSLATE_TITLE_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "TITLE";
const _TRANSLATE_CLEAR_CACHE_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "CLEAR-CACHE";
const _TRANSLATE_CLEAR_ALL_DATA_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "CLEAR-ALL-DATA";
export class AuthenticationService {
    async _initAuthenticationServiceProvider(authConfig, loggingService) {
        const provider = authConfig.provider || PROVIDER_AUTH0;
        if (PROVIDER_AUTH0 === provider) {
            this._authSPI = new Auth0AuthenticationServiceProvider(loggingService);
        }
        else if (PROVIDER_ENTRA === provider) {
            this._authSPI = new EntraAuthenticationServiceProvider(loggingService);
        }
        else {
            throw new Error(`Authentication service provider '${provider} not recognized!`);
        }
        await this._authSPI.init(authConfig);
    }
    constructor(_loggingService, _store, _notificationsService, _translateService) {
        this._loggingService = _loggingService;
        this._store = _store;
        this._notificationsService = _notificationsService;
        this._translateService = _translateService;
        this._logger = new Logger(AUTHENTICATION_LOG_NAMESPACE, _loggingService);
    }
    /**
     * Initializes the Auth0 client and handles a redirect callback if present.
     */
    async init(authConfig) {
        authConfig = Object.assign({}, authConfig);
        if (authConfig?.customConfig) {
            try {
                authConfig.customConfig = JSON.parse(authConfig.customConfig);
            }
            catch (err) {
                this._logger.error("Could not parse custom configuration into a valid JSON object.  Leaving unchanged.  Please ensure if Authentication.customConfig is present that it is a parseable JSON object string.", err);
            }
        }
        this._authConfig = authConfig;
        await this._initAuthenticationServiceProvider(authConfig, this._loggingService);
    }
    async authenticate() {
        return this._authSPI.authenticate();
    }
    async authDetails() {
        return this._authSPI.authDetails();
    }
    async userProfile() {
        return this._authSPI.userProfile();
    }
    /**
     * Logs out the user.
     * Handles mouse/touch events to optionally show logout options,
     * copy token to clipboard, or perform a full logout.
     */
    async logout(event, token) {
        if (event == null || event instanceof MouseEvent && false == event.shiftKey) {
            await this._performLogout(true);
        }
        else if (event instanceof MouseEvent) {
            return this._handleLogoutClicked(event, token);
        }
        else {
            return this._handleLogoutTapped(event, token);
        }
    }
    /**
     * Performs a full logout: dispatches logout actions and calls Auth0's logout.
     */
    async _performLogout(reloadPage = false) {
        this._store.dispatch(new UserLogoutInitiated());
        await firstValueFrom(this._store.select("user").pipe(first(user => user.auth?.isAuthenticated === false)));
        this._authSPI.logout();
        this._store.dispatch(new UserReset());
        if (true == reloadPage) {
            console.log("Reloading page...");
            this._reloadPage();
        }
    }
    /**
     * Handles logout triggered by a touch event.
     */
    async _handleLogoutTapped(event, token) {
        const isLongPress = (event === undefined || event === true) ? true : false;
        if (isLongPress) {
            this._showLogoutOptions();
        }
        else {
            this._performLogout(true);
        }
    }
    /**
     * Handles logout triggered by a mouse click.
     * Copies the auth token to clipboard if shift+alt are held,
     * shows logout options if only shift is held, otherwise logs out.
     */
    async _handleLogoutClicked(event, token) {
        if (this._copyAuthTokenToClipboardTriggered(event, token)) {
            console.log("Authentication token copied to clipboard.");
            copyToClipboard(`${HEADER_BEARER} ${token}`);
            return;
        }
        else if (this._showLogoutOptionsTriggered(event)) {
            return this._showLogoutOptions();
        }
        else {
            return this._performLogout();
        }
    }
    /**
     * Displays logout options via notifications.
     */
    _showLogoutOptions() {
        this._notificationsService.post({
            message: this._translateService.instant(_TRANSLATE_TITLE_KEY),
            showCloseButton: true,
            actions: {
                clearCache: {
                    label: this._translateService.instant(_TRANSLATE_CLEAR_CACHE_KEY),
                    action: async () => this._clearAuthenticationAndLogout()
                },
                clearAll: {
                    label: this._translateService.instant(_TRANSLATE_CLEAR_ALL_DATA_KEY),
                    action: async () => this._clearLocalStorageAndLogout()
                }
            }
        });
    }
    /**
     * Returns true if shift+alt are held and a token exists.
     */
    _copyAuthTokenToClipboardTriggered(event, token) {
        return !!(event.shiftKey && event.altKey && token);
    }
    /**
     * Returns true if only shift is held.
     */
    _showLogoutOptionsTriggered(event) {
        return !!(event.shiftKey);
    }
    /**
     * Clears authentication data (from store/localStorage) and forces a reload.
     */
    async _clearAuthenticationAndLogout() {
        await this._performLogout();
        this._reloadPage(true);
    }
    /**
     * Clears all local storage and forces a reload.
     */
    async _clearLocalStorageAndLogout() {
        await this._performLogout();
        localStorage.clear();
        this._reloadPage(true);
    }
    /**
     * Forces a full page reload.
     */
    _reloadPage(withCacheBuster = false) {
        // Create a URL object from the current location.
        var url = new URL(window.location.href);
        if (true == withCacheBuster) {
            // Set or update the "cacheBuster" parameter to the current timestamp.
            url.searchParams.set("cacheBuster", new Date().getTime().toString());
        }
        // Redirect the browser to the new URL.
        window.location.href = url.toString();
    }
    authenticationToken() {
        return null != this._authSPI ? this._authSPI.authenticationToken() : null;
    }
    isTokenExpired() {
        return null != this._authSPI ? this._authSPI.isTokenExpired() : true;
    }
    storeUserContext(userContext) {
        localStorage.setItem(LS_USER, JSON.stringify(userContext));
    }
}
AuthenticationService.ɵfac = function AuthenticationService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || AuthenticationService)(i0.ɵɵinject(i1.LoggingService), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.NotificationsService), i0.ɵɵinject(i4.TranslateService)); };
AuthenticationService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthenticationService, factory: AuthenticationService.ɵfac });
export { AUTH_PICTURE, AUTH_USER_NAME };
