import { Apollo } from "apollo-angular";
// import {
//   HttpLink
// } from "apollo-angular-link-http";
import { HttpLink } from "apollo-angular/http";
import {
  InMemoryCache,
  InMemoryCacheConfig
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

import possibleTypes from "./possibleTypes.json";
import { AuthenticationService } from "../auth/authentication.service";

let _client: any;

function createApolloClient(
    apollo: Apollo,
    httpLink: HttpLink,
    authenticationService: AuthenticationService
) {

  const http = httpLink.create({
    uri: process.env["DDC_GQL_SERVER_URL"]
  });

  const middleware = setContext((_, { headers }) => {
    const authToken = authenticationService.authenticationToken();
    if (null!= authToken) {
      const ret = {
        headers: {
          ...headers,
          authorization: `Bearer ${authToken}`,
          uniqueId: Date.now().toString()
        }
      };
      return ret;
    } else {
      return {
        ...headers
      };
    }
  });

  const link = middleware.concat(http) as any;

  const cacheConfig: InMemoryCacheConfig = {
    dataIdFromObject: ((o: any) => {
      if (o["guid"]) {
        return o["guid"];
      }

      if (o["id"] && o["__typename"]) {
        return `${o["__typename"]}:${o["id"]}`;
      }
      // console.log(o);
      return null;
    }),
    typePolicies: {
      Configuration: {
        keyFields: false,
      },
      PeriodServices: {
        keyFields: false
      },
      Assets: {
        keyFields: false
      },
      Query: {
        fields: {
          Configuration: {
            merge: false
          },
          PeriodServices: {
            merge: false
          }
        }
      },
      CalendarDateRange: {
        keyFields: false // Don't try to normalize date ranges.
      },
      BusinessDate: {
        fields: {
          CalendarDateRange: {
            merge: false
          },
        },
      },
      // ... other type policies ...
    },
    addTypename: true,
    possibleTypes: possibleTypes
  };

  const cache = new InMemoryCache(cacheConfig);

  apollo.create({
    link: link,
    cache: cache as any,
    ssrMode: true,
    ssrForceFetchDelay: 100,
    connectToDevTools: true,
    queryDeduplication: true
  });

  _client = apollo.client;

  return apollo;
}

async function resetApolloClientStore() {
  try {
    await _client.cache.reset();
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export {
  createApolloClient,
  resetApolloClientStore
};
