import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import { LOGIN_ROUTE } from "./../../util/string-constants";
import { AuthenticationService } from "./authentication.service";
import * as i0 from "@angular/core";
import * as i1 from "../logging/logging.service";
import * as i2 from "./authentication.service";
import * as i3 from "@angular/router";
const _LOG_NAMESPACE = "AuthGuards";
export class IsAuthenticatedGuard {
    constructor(loggingService, _authenticationService, _router) {
        this._authenticationService = _authenticationService;
        this._router = _router;
        this._logger = new Logger(_LOG_NAMESPACE, loggingService);
    }
    async canActivate(next, state) {
        const isAuthorized = (await this._authenticationService.authDetails()).isAuthenticated;
        if (!isAuthorized) {
            this._logger.warn("Authentication guard doesn't think you are logged in.  Redirecting to login page.");
            this._router.navigateByUrl(LOGIN_ROUTE);
            return false;
        }
        else {
            return true;
        }
    }
    async canActivateChild(next, state) {
        return this.canActivate(next, state);
    }
}
IsAuthenticatedGuard.ɵfac = function IsAuthenticatedGuard_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || IsAuthenticatedGuard)(i0.ɵɵinject(i1.LoggingService), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.Router)); };
IsAuthenticatedGuard.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: IsAuthenticatedGuard, factory: IsAuthenticatedGuard.ɵfac });
