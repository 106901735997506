var User_1;
import { __decorate, __metadata } from "tslib";
import { Any, JsonObject, JsonProperty } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
import { json2ts } from "../../util/json-2ts";
import { Features, FeaturesConverter } from "./features-model";
import { Preferences, PreferencesConverter } from "./preferences-model";
import { Settings, SettingsConverter } from "./settings.model";
const _DEFAULT_CONTEXT = {
    reference: {},
    temporal: {},
    dimension: {}
};
let User = User_1 = class User {
    constructor() {
        this.guid = undefined;
        this.id = undefined;
        this.displayName = undefined;
        this.nickName = undefined;
        this.avatarURL = undefined;
        this.email = undefined;
        this.status = undefined;
        this.role = undefined;
        this.Settings = new Settings();
        this.Features = new Features();
        this.Preferences = new Preferences();
        this.DefaultContext = _DEFAULT_CONTEXT;
        this.valid = false;
    }
    static ToValidContextObject(data) {
        if (data instanceof User_1) {
            return data;
        }
        else {
            return json2ts(data, User_1);
        }
    }
    static ToValidInputObject(data) {
        if (null == data) {
            return null;
        }
        return {
            id: data.id
        };
    }
    static clone(toClone) {
        if (null == toClone) {
            return null;
        }
        const ret = new User_1();
        ret.guid = toClone.guid;
        ret.id = toClone.id;
        ret.displayName = toClone.displayName;
        ret.nickName = toClone.nickName;
        ret.avatarURL = toClone.avatarURL;
        ret.email = toClone.email;
        ret.role = toClone.role;
        ret.Settings = new Settings();
        Object.assign(ret.Settings, toClone.Settings);
        ret.Features = new Features();
        Object.assign(ret.Features, toClone.Features);
        ret.Preferences = new Preferences();
        Object.assign(ret.Preferences, toClone.Preferences);
        ret.DefaultContext = {};
        Object.assign(ret.DefaultContext, toClone.DefaultContext);
        return ret;
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_GUID, String, false),
    __metadata("design:type", String)
], User.prototype, "guid", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_ID, String, false),
    __metadata("design:type", String)
], User.prototype, "id", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_DISPLAY_NAME, String, false),
    __metadata("design:type", String)
], User.prototype, "displayName", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NICKNAME, String, true),
    __metadata("design:type", String)
], User.prototype, "nickName", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_AVATAR_URL, String, false),
    __metadata("design:type", String)
], User.prototype, "avatarURL", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_EMAIL, String, true),
    __metadata("design:type", String)
], User.prototype, "email", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_STATUS, String, true),
    __metadata("design:type", String)
], User.prototype, "status", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_ROLE, String, true),
    __metadata("design:type", String)
], User.prototype, "role", void 0);
__decorate([
    JsonProperty(GQL.SETTINGS, SettingsConverter, true),
    __metadata("design:type", Settings)
], User.prototype, "Settings", void 0);
__decorate([
    JsonProperty(GQL.FEATURES, FeaturesConverter, true),
    __metadata("design:type", Features)
], User.prototype, "Features", void 0);
__decorate([
    JsonProperty(GQL.PREFERENCES, PreferencesConverter, true),
    __metadata("design:type", Preferences)
], User.prototype, "Preferences", void 0);
__decorate([
    JsonProperty(GQL.DEFAULT_CONTEXT, Any, true),
    __metadata("design:type", Object)
], User.prototype, "DefaultContext", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_VALID, Boolean, true),
    __metadata("design:type", Boolean)
], User.prototype, "valid", void 0);
User = User_1 = __decorate([
    JsonObject("User")
], User);
export { User };
