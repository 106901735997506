import { Logger } from "../../logging/logger";
import * as fromUser from "../../../context/user.reducers"
import { Authorization } from "../../../model/user/authorization.model";
import { AuthConfiguration } from "../auth-configuration.model";

export const AUTHENTICATION_LOG_NAMESPACE: string = "services.authentication";
export const DIDICI_NAMESPACE = "https://didici.io/";
export const AUTH_USER_NAME = DIDICI_NAMESPACE + "name";
export const AUTH_PICTURE = DIDICI_NAMESPACE + "picture";
export const AUTH_AUTHORIZATION = DIDICI_NAMESPACE + "authorization";

export interface AuthResult {
  isError: boolean,
  auth?: fromUser.Auth,
  error?: any
}

export interface UserProfileResult {
  isError: boolean;
  userProfile?: any;
  authorization?: Authorization,
  error?: any
}

export interface AuthenticationServiceProvider {
  init(authConfig: AuthConfiguration): Promise<void>;
  isAuthenticated(): boolean;
  authenticate(): Promise<AuthResult>;
  logout();
  authDetails(): Promise<fromUser.Auth>;
  userProfile(): Promise<UserProfileResult>;
  authenticationToken(): string | null;
  isTokenExpired(): boolean;
}
