export const CONFIG: string = "config";
export const COMPOSITIONS: string = "compositions";
export const NAMED_FORMATS: string = "named-formats";
export const THRESHOLDS: string = "thresholds";
export const PROPERTY_NAME: string = "property-name";
export const EMPTY: string = "";
export const EXTENT: string = "extent";
export const NOOP: string = "noop";
export const TRANSLATION: string = "translation";
export const REFERENCE: string = "reference";
export const PERIOD: string = "period";
export const TIMESTAMP: string = "timestamp";
export const PREFIX: string = "prefix";
export const SUFFIXES: string = "suffixes";
export const SINGULAR: string = "singular";
export const PLURAL: string = "plural";
export const DEFAULT: string = "default";
export const DEFAULT_TYPE: string = "default-type";
export const LONG: string = "long";
export const TINY: string = "tiny";
export const NAME: string = "name";
export const ICON: string = "icon";
export const POPUP: string = "popup";
export const STARTING_FISCAL_MONTH: string = "starting-fiscal-month";
export const NOT_A_DATE_DEFAULT_VALUE: string = "not-a-date-default-value";
export const USE_CALENDAR_DATE_ELEMENT: string = "use-calendar-date-element";
export const INBOUND: string = "inbound";
export const OUTBOUND: string = "outbound";
export const BUSINESS_DATE: string = "business-date";
export const BUSINESS_DATE_FORMATTER: string = "business-date-formatter";
export const DIMENSION_PAYLOAD: string = "dimension-payload";
export const BUSINESS_DATE_RANGE: string = `${BUSINESS_DATE}-range`;
export const TEMPORAL_AGGREGATION: string = "temporal-aggregation";
export const TEMPORAL_AGGREGATION_MAPPINGS: string = "temporal-aggregation-mappings";
export const ABSOLUTE_RANGE_TEMPORAL_AGGREGATION: string = `absolute-range-${TEMPORAL_AGGREGATION}`;
export const TEMPORAL_AGGREGATIONS: string = `${TEMPORAL_AGGREGATION}s`;
export const DEFAULT_CONTEXT = "default-context";
export const NONE: string = "none";
export const JSON_PATH: string = "json-path";
export const INDEX: string = "index";
export const ACTION_TEMPLATE = "action-template";
export const KEY: string = "key";
export const VALUE: string = "value";
export const VALUE_JSON_PATH: string = "value-" + JSON_PATH;
export const VALUES_JSON_PATHS: string = "values-" + JSON_PATH + 's';
export const COLOUR_BRAND_SELECTOR: string = "colour-brand-selector";
export const STYLES_BRAND_SELECTOR_CODE: string = "styles-brand-selector-code";
export const STYLES_BRAND_SELECTOR_PROPERTIES: string = "styles-brand-selector-properties";
export const SHOW_VALUE: string = "show-value";
export const VALUE_FORMATTER: string = "value-formatter";
export const DISPLAY: string = "display";
export const TYPE: string = "type";
export const ID: string = "id";
export const LABEL: string = "label";
export const CONTAINER: string = "container";
export const TITLE: string = "title";
export const UPDATE_CONTEXT_ON_MISMATCH: string = "update-context-on-mismatch";
export const DEFINITIONS: string = "definitions";
export const TEMPORAL_SHORTCUTS_UX_FACADE: string = "temporal-shortcuts-ux-facade";
export const FILTER_GROUPS: string = "filter-groups";
export const EDITOR: string = "editor";
export const OPTIONS: string = "options";
export const CONTENT: string = "content";
export const DATA_REGISTRATION: string = "data-registration";
export const COMMENTS_UX_FACADE: string = "comments-ux-facade";
export const FORMATTER: string = "formatter";
export const PARAMS: string = "params";
export const FORMAT: string = "format";
export const FORMATTERS: string = "formatters";
export const SEPARATOR: string = "separator";
export const BRAND_SELECTOR: string = "brand-selector";
export const SELECTOR_STYLES: string = "selector-styles";
export const AWAIT_ALL_COMPONENT_REFRESH: string = "await-all-component-refresh";
export const SELECTED_PERIOD_DISPLAY: string = "selected-period-display";
export const CALENDAR_DATE_ELEMENTS: string = "calendar-date-element";
export const LC_FROM: string = "from";
export const LC_TO: string = "to";
export const PERMISSIONS = "permissions";
export const ROLES:string = "roles";
export const REQUIRED_PERMISSIONS = "required-permissions";
export const REQUIRE_ALL = "require-all";
export const DATA_HANDLING: string = "data-handling";
export const TREAT_AS_NO_DATA_AVAILABLE_IF_ALL_VALUES_ARE_NULL: string = "treat-as-no-data-available-if-all-values-null";
export const VALUE_MAPPINGS = "value-mappings";
export const TRANSLATION_KEY = "translation-key";
export const DEFAULT_TRANSLATION_KEY = "default-translation-key";
export const DEFAULT_PASS = "default-pass";
export const FALLBACK_FORMATTER = "fallback-formatter";
export const IS_FALLBACK = "isFallback";
