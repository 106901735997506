import { platformBrowser } from "@angular/platform-browser";
import { decorateModuleRef } from "./app/environment";
import { enableProdMode } from "@angular/core";

import { AppModule } from "./app/app.module";

// import { appConfig } from './app/app.config';
console.log("AOT Build Loading...");

if (process.env.ENV === "production") {
  enableProdMode();
}

platformBrowser()
  .bootstrapModule(AppModule)
  .then(decorateModuleRef)
  .catch((err) => console.error(err));
