import { JwtHelperService } from "@auth0/angular-jwt"; // Utility class, not tied to Auth0 authentication.
import { UserContextKeys } from "../../../context/context";
import * as fromUser from '../../../context/user.reducers';
import { LS_USER } from '../../../util/string-constants';
import { AuthenticationServiceProvider, AuthResult, UserProfileResult } from "./auth-spi.interface";
import { AuthConfiguration } from "../auth-configuration.model";
import { LoggingService } from "../../logging/logging.service";
import { Logger } from "../../logging/logger";
import { json2ts, ts2json } from "../../../util/json-2ts";
import { Authorization } from "../../../model/user/authorization.model";

export const TOKEN_SUB = "sub";

const _JWT_HELPTER = new JwtHelperService();


// export interface AuthenticationCheckResult {
//   isTokenExpired: boolean;
//   auth?: any;
// }

/**
 *  The data layer needs to be able to be called BEFORE the ngrx state has been initialised because when the callback comes from the authentication service
 * that is a full page reload, so things get initialized all over again.
 * So, to work around this (and it's an acknowledged hack but it's pretty harmless)
 * we write DIRECTLY to local storage and the data layer reads DIRECTLY from local storage.
 * Hence it's encapsulated into these two helper protecteds.
 */
export abstract class BaseAuthSPI implements AuthenticationServiceProvider {

  public abstract  init(authConfig: AuthConfiguration): Promise<void>;
  public abstract  authenticate(): Promise<AuthResult>;
  public abstract  logout();
  public abstract  authDetails(): Promise<fromUser.Auth>;
  public abstract  userProfile(): Promise<UserProfileResult>;

  protected abstract _cleanUpAuthData(): void;

  protected _logger: Logger;
  protected _authConfig: AuthConfiguration;

  public constructor(loggingService: LoggingService, logNamespace: string) {
    this._logger = new Logger(logNamespace, loggingService);
    this._cleanUpAuthData();
  }

  protected storeUserContext(auth: fromUser.UserContext) {
    localStorage.setItem(LS_USER, JSON.stringify(auth));
  }

  protected authToLocalStorage(auth: fromUser.Auth | null) {
    const val = JSON.parse(localStorage.getItem(LS_USER) || "{}");

    // console.log("VAL", val);
    // console.log("TO STORE", auth.authorization);
    if (null != auth?.authorization) {
      auth.authorization = ts2json(auth.authorization);
    }
    // console.log("TO STORE AFTER JSON", auth.authorization);
    val[UserContextKeys.AUTH] = auth;
    // console.log("VAL AFTER UPDATE", val);
    const updated = JSON.stringify(val);
    // console.log("VAL STRING AFTER UPDATE", updated);
    localStorage.setItem(LS_USER, updated);
    // console.log("RETRIEVE FROM STORED BEFORE RETURN", localStorage.getItem(LS_USER));
  }

  protected authFromLocalStorage(): fromUser.Auth | null {
    const lsUser = localStorage.getItem(LS_USER);
    if (null != lsUser) {
      const userContext: fromUser.UserContext = JSON.parse(lsUser);
      if (userContext && userContext.auth) {
        if (userContext.auth.authorization) {
          // console.log("GOT THIS FROM LOCAL STORAGE", userContext.auth.authorization);
          userContext.auth.authorization = json2ts(userContext.auth.authorization, Authorization);
        }
        // console.log("RETURNING", userContext.auth, userContext.auth.authorization);
        return userContext.auth;
      } else {
        return null;
      }
    } else {
      return null;
    }

  }


  protected getAuthToken(auth: fromUser.Auth | null = null): string | null {
    let authToken: string | undefined;
    if (null == auth) {
      auth = this.authFromLocalStorage();
    }
    if (auth) {
      authToken = auth?.accessToken;
    }
    return authToken ? authToken : null;
  }


  // protected checkAuthentication(): AuthenticationCheckResult {
  //   const auth = this.authFromLocalStorage();
  //   const token = this.getAuthToken(auth);
  //   const isExpired = this.isTokenExpired();
  //   let ret: AuthenticationCheckResult;
  //   if (isExpired) {
  //     ret = {isTokenExpired: true};
  //   } else {
  //     ret = {isTokenExpired: false, auth};
  //   }
  //   return ret;
  // }

  public isTokenExpired() {
    const token = this.getAuthToken();
    // console.log("TOKEN", token);
    if (null == token) {
      return true;
    }
    return _JWT_HELPTER.isTokenExpired(token);
  }

  protected typedAuthorization(untypedAuthorization: any): Authorization {
    return untypedAuthorization ? json2ts(untypedAuthorization, Authorization) : undefined;
  }

  protected checkTokenExpiration(token: string): boolean {
    if (null == token) {
      return true;
    } else {
      return _JWT_HELPTER.isTokenExpired(token);
    }
  }

  public authenticationToken(): string | null {
    return this.getAuthToken();
  }


  public isAuthenticated(): boolean {
    const token = this.getAuthToken();
    return this.checkTokenExpiration(token);
  }

};

