import { JsonObject, JsonProperty } from "json2typescript";

export const GROUPS = "groups";
export const ROLES = "roles";
export const PERMISSIONS = "permissions";

@JsonObject("Authorization")
export class Authorization {
  @JsonProperty(GROUPS, [String], true)
  public Groups: string[] = [];

  @JsonProperty(ROLES, [String], true)
  public Roles: string[] = [];

  @JsonProperty(PERMISSIONS, [String], true)
  public Permissions: string[] = [];

}
