import * as lda from "lodash";
import * as fromUser from "../../../context/user.reducers";
import { AUTH_AUTHORIZATION, AUTHENTICATION_LOG_NAMESPACE, AuthResult, UserProfileResult } from "./auth-spi.interface";

import { AccountInfo, AuthenticationResult, Configuration, PublicClientApplication, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { Authorization, ROLES } from "../../../model/user/authorization.model";
import { formattedTimeString, normalizeString } from "../../../util/utils";
import { LoggingService } from "../../logging/logging.service";
import { AuthConfiguration } from "../auth-configuration.model";
import { BaseAuthSPI, TOKEN_SUB } from "./base-auth-spi";


export const PROVIDER_ENTRA = "entra";
const _LOG_NAMESPACE = AUTHENTICATION_LOG_NAMESPACE + `.${PROVIDER_ENTRA}`;

export class EntraAuthenticationServiceProvider extends BaseAuthSPI  {


  private _emailDomain: string | null = null;
  private _forceEmailDomain: boolean = false;
  private _validationClaims: any[] = null;
  private _msalInstance: PublicClientApplication;

  public constructor(loggingService: LoggingService) {
    super(loggingService, _LOG_NAMESPACE);
  }

  public async init(authConfig: AuthConfiguration): Promise<void> {
    this._authConfig = authConfig;

    if (authConfig?.customConfig?.emailDomain) {
      this._emailDomain = authConfig.customConfig.emailDomain.domain || null;
      this._forceEmailDomain = authConfig.customConfig.emailDomain.force || false;
    }

    this._logger.warn(formattedTimeString() + " Initializing Microsoft Entra Authentication Service Provider...");

    const clientOptions: Configuration = {
      auth: {
        clientId: authConfig.ulClientID,
        authority: authConfig.domain,
        redirectUri: window.location.origin + authConfig.callbackURL,
        postLogoutRedirectUri: window.location.origin + authConfig.logoutURL
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
      }
    };

    this._msalInstance = new PublicClientApplication(clientOptions);
    await this._msalInstance.initialize();


    // throw new Error("STOP!");

    // Handle the redirect callback—this is the MSAL equivalent of checking for "code" and "state" in the URL.
    try {
      const authResult: AuthenticationResult | null = await this._msalInstance.handleRedirectPromise();
      if (null != authResult) {
        this._logger.warn("Handled MSAL redirect callback successfully.", authResult);
        // Set the active account for subsequent token requests
        this._msalInstance.setActiveAccount(authResult.account);
        const auth = await this.authDetails();
        console.log("ABOUT TO STORE AUTH", auth);
        this.authToLocalStorage(auth);
        console.log("AUTH STORED");
        // Optionally, clean up the URL by removing query parameters:
        window.history.replaceState({}, document.title, window.location.pathname);
      } else {
        this._logger.info("No MSAL redirect callback detected.");
      }
    } catch (error) {
      this._logger.error("Error handling MSAL redirect callback", error);
    }

  }


  private async _isAuthenticated(): Promise<boolean> {
    const auth = this.authFromLocalStorage();
    if (null == auth) {
      return false;
    }
    const details = await this._getTokenSilently();
    const ret = !this.checkTokenExpiration(normalizeString(details?.result.idToken));
    return ret;
  }

  private _loginRequestParams(forcePrompt: boolean = false) {
    const loginRequest: RedirectRequest = {
      scopes: [this._authConfig.scope || "User.Read"],
      // Optionally, pass the current URL as the starting page to return to
      redirectStartPage: window.location.pathname
    };

    if (this._emailDomain) {
      loginRequest.domainHint = this._emailDomain;
    }

    if (true === forcePrompt) {
      loginRequest.prompt = "select_account";
    }
    return loginRequest;

  }

  private _hasRequiredTokenClaims(claims: { [key: string]: any }): boolean {

    if (this._authConfig?.customConfig?.validation?.claims) {
      for (const claim of this._authConfig.customConfig.validation.claims) {
        const val = claims[claim];
        this._logger.warn(`${formattedTimeString()} Checking claims for '${claim}'; Value is: '${val}'.`);
        if (null == claims[claim]) {
          this._logger.warn(`${formattedTimeString()} Claims did not contain a value for '${claim}'  Claims are not valid.`);
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  }

  public async authenticate(): Promise<AuthResult> {
    // Check if the user is already authenticated
    const isAuthenticated = await this._isAuthenticated();
    let tokenDetails: {
      account: AccountInfo,
      result: AuthenticationResult
    };
    if (!isAuthenticated) {
      this._logger.warn("User is not authenticated. Redirecting to MSAL login.");

      // Prepare the login request.
      const loginRequest = this._loginRequestParams();

      // Initiate the login redirect. The browser will navigate away.
      // Note: loginRedirect() returns void, so the promise doesn't complete until the user returns.
      this._msalInstance.loginRedirect(loginRequest);

      // Return an interim AuthResult.
      return { isError: false };
    } else {
       // User is authenticated; verify the email domain
      tokenDetails = await this._getTokenSilently();
      if (tokenDetails && tokenDetails.account && this._emailDomain && true === this._forceEmailDomain) {
        const email = tokenDetails.account.username;
        const domain = email.substring(email.lastIndexOf("@") + 1).toLowerCase();
        if (domain !== this._emailDomain) {
          this._logger.warn(
            `Authenticated user domain "${domain}" does not match "${this._emailDomain}".  Prompting account selection.`
          );
          // Force interactive login to select the correct account
          const loginRequest = this._loginRequestParams(true);
          this._msalInstance.loginRedirect(loginRequest);
          return { isError: false };
        } else {
          this._logger.warn(
            `Authenticated user domain "${domain}" matches "${this._emailDomain}".  Proceeding.`
          );
        }

      } else {
        this._logger.warn(`Email domain verification not enabled.  Assuming user ${tokenDetails?.account.username} is valid.`);
      }
    }

    this._logger.warn("User is authenticated. Proceeding.");
    // User is authenticated; retrieve authentication details.
    const auth = await this.authDetails();
    const userProfile = tokenDetails.account; // Contains properties like name, username, etc.

    // Access custom claims from the idTokenClaims property.
    const claims = userProfile.idTokenClaims as { [key: string]: any };

    const valid = this._hasRequiredTokenClaims(claims);
    if (true === valid) {
      return {
        isError: false,
        error: null,
        auth: auth
      };
    } else {
      // Force interactive login to select an account with the right claims
      const loginRequest = this._loginRequestParams(true);
      this._msalInstance.loginRedirect(loginRequest);
      return {
        isError: false
      };
    }
  }


  public async logout(): Promise<void> {
    try {
      // Initiates the logout process.
      // This call clears local tokens and redirects the browser to the Azure AD logout endpoint.
      sessionStorage.clear();
      await this._msalInstance.logoutRedirect({

      });
    } catch (error) {
      this._logger.error("Error during logout", error);
    }
  }

  private async _getTokenSilently(): Promise<{result: AuthenticationResult, account: AccountInfo} | null> {
    const activeAccount = this._msalInstance.getActiveAccount();
    if (!activeAccount) {
      return null;
    }

    const silentRequest: SilentRequest = {
      scopes: [this._authConfig.scope || "User.Read"],
      account: activeAccount,
    };

    const result = await this._msalInstance.acquireTokenSilent(silentRequest);

    // Acquire token silently.
    const ret = {
      result: result,
      account: activeAccount
    };
    return ret;

  }

  private mergedRolesAuthorization(activeAccount: AccountInfo): Authorization {
    const claims = activeAccount.idTokenClaims as { [key: string]: any };
    const authorizationString: string = (claims[AUTH_AUTHORIZATION] || "{}").trim(); // String will have leading and trailing single quotes we need to remove to make it valid JSON.
    const authorization =JSON.parse(authorizationString);
    const roles = lda.union(authorization[ROLES] ? authorization[ROLES] : [], activeAccount.idTokenClaims?.roles);
    authorization[ROLES] = roles;
    return this.typedAuthorization(authorization);
  }


  public async authDetails(): Promise<fromUser.Auth> {
    try {

      const details = await this._getTokenSilently();
      const activeAccount = details?.account;

      if (!activeAccount) {
        throw new Error("No active account found.");
      }

      const result = details.result;
      const accessToken = result.accessToken;

      const authResult: fromUser.Auth = {
        provider: PROVIDER_ENTRA,
        isAuthenticated: true,
        accessToken: accessToken,
        authorization: this.mergedRolesAuthorization(activeAccount)
      };

      // Optionally, store the authentication state.
      // console.log("ABOUT TO STORE AUTH RESULT", authResult, authResult.authorization);
      this.authToLocalStorage(authResult);
      this._logger.info("User is already authenticated", activeAccount);
      return authResult;
    } catch (error) {
      this._logger.error("Error during silent authentication", error);
      return { isAuthenticated: false };
    }
  }


  public async userProfile(): Promise<UserProfileResult> {
    try {
      // Retrieve the active account.
      const userProfile: AccountInfo | null = this._msalInstance.getActiveAccount();
      if (!userProfile) {
        throw new Error("No active account found.");
      }

      userProfile[TOKEN_SUB] = userProfile.idTokenClaims?.sub;
      const claims = userProfile.idTokenClaims as { [key: string]: any } | {};

      this._logger.warn("Retrieved user profile:", userProfile);
      return {
        isError: false,
        userProfile,
        authorization: this.mergedRolesAuthorization(userProfile)
      };
    } catch (error) {
      const errorMessage = "There was an error retrieving profile data.";
      this._logger.error(errorMessage, error);
      return {
        isError: true,
        error: errorMessage + error.toString()
      };
    }
  }


  protected _cleanUpAuthData(): void {

    const ret =  this.authFromLocalStorage();
    if (null != ret) {
      if (PROVIDER_ENTRA !== ret.provider) {
        this._logger.warn(formattedTimeString()+ " The stored authentication is for a different provider.  Removing.");
        this.authToLocalStorage(null);
      }
    }
  }

}
