import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as fromContext from "../../context/context";
import * as fromUser from "../../context/user.reducers";
import { UserLogoutInitiated, UserReset } from "../../context/user.actions";
import { copyToClipboard } from "../../util/utils";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import { NotificationsService } from "../notifications/notifications.service";

import { AUTH_PICTURE, AUTH_USER_NAME, AUTHENTICATION_LOG_NAMESPACE } from "./spi/auth-spi.interface";

// String constants
import { first, firstValueFrom } from "rxjs";
import {
  HEADER_BEARER,
  LS_USER
} from "../../util/string-constants";
import { AuthenticationServiceProvider, AuthResult } from "./spi/auth-spi.interface";
import { Auth0AuthenticationServiceProvider, PROVIDER_AUTH0 } from "./spi/auth0-spi.service";
import { EntraAuthenticationServiceProvider, PROVIDER_ENTRA } from "./spi/entra-spi.service";
import { AuthConfiguration } from "./auth-configuration.model";


const _TRANSLATE_UX_PLACEHOLDERS_PFX = "UX.PLACEHOLDERS.";
const _TRANSLATE_LOGOUT_OPTIONS_PFX = _TRANSLATE_UX_PLACEHOLDERS_PFX + "LOGOUT-OPTIONS.";
const _TRANSLATE_TITLE_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "TITLE";
const _TRANSLATE_CLEAR_CACHE_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "CLEAR-CACHE";
const _TRANSLATE_CLEAR_ALL_DATA_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "CLEAR-ALL-DATA";


@Injectable()
export class AuthenticationService {

  private _authConfig: AuthConfiguration;
  private _authSPI: AuthenticationServiceProvider;
  private _logger: Logger;

  private async _initAuthenticationServiceProvider(authConfig: AuthConfiguration, loggingService: LoggingService) {
    const provider = authConfig.provider || PROVIDER_AUTH0;

    if (PROVIDER_AUTH0 === provider) {
      this._authSPI = new Auth0AuthenticationServiceProvider(loggingService);
    } else if (PROVIDER_ENTRA === provider) {
      this._authSPI = new EntraAuthenticationServiceProvider(loggingService);
    } else {
      throw new Error(`Authentication service provider '${provider} not recognized!`);
    }

    await this._authSPI.init(authConfig);
  }

  constructor(
    private _loggingService: LoggingService,
    private _store: Store<fromContext.Context>,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService
  ) {
    this._logger = new Logger(AUTHENTICATION_LOG_NAMESPACE, _loggingService);
  }

  /**
   * Initializes the Auth0 client and handles a redirect callback if present.
   */
  public async init(authConfig: AuthConfiguration): Promise<void> {
    authConfig = Object.assign({}, authConfig);
    if (authConfig?.customConfig) {
      try {
        authConfig.customConfig = JSON.parse(authConfig.customConfig);
      } catch (err) {
        this._logger.error("Could not parse custom configuration into a valid JSON object.  Leaving unchanged.  Please ensure if Authentication.customConfig is present that it is a parseable JSON object string.", err);
      }
    }
    this._authConfig = authConfig;
    await this._initAuthenticationServiceProvider(authConfig, this._loggingService);
  }

  public async authenticate(): Promise<AuthResult> {
    return this._authSPI.authenticate();
  }

  public async authDetails(): Promise<fromUser.Auth> {
    return this._authSPI.authDetails();
  }

  public async userProfile() {
    return this._authSPI.userProfile();
  }

  /**
   * Logs out the user.
   * Handles mouse/touch events to optionally show logout options,
   * copy token to clipboard, or perform a full logout.
   */
  public async logout(event?: MouseEvent | TouchEvent | boolean | null, token?: string): Promise<void> {
    if (event == null || event instanceof MouseEvent && false == event.shiftKey) {
      await this._performLogout(true);
    } else if (event instanceof MouseEvent) {
      return this._handleLogoutClicked(event, token);
    } else {
      return this._handleLogoutTapped(event, token);
    }
  }



  /**
   * Performs a full logout: dispatches logout actions and calls Auth0's logout.
   */
  private async _performLogout(reloadPage: boolean = false): Promise<void> {
    this._store.dispatch(new UserLogoutInitiated());

    await firstValueFrom(
      this._store.select("user").pipe(
        first(user => user.auth?.isAuthenticated === false)
      )
    );

    this._authSPI.logout();
    this._store.dispatch(new UserReset());
    if (true == reloadPage) {
      console.log("Reloading page...");
      this._reloadPage();
    }
  }


  /**
   * Handles logout triggered by a touch event.
   */
  private async _handleLogoutTapped(event: TouchEvent | boolean, token?: string): Promise<void> {
    const isLongPress: boolean = (event === undefined || event === true) ? true : false;
    if (isLongPress) {
      this._showLogoutOptions();
    } else {
      this._performLogout(true);
    }
  }

  /**
   * Handles logout triggered by a mouse click.
   * Copies the auth token to clipboard if shift+alt are held,
   * shows logout options if only shift is held, otherwise logs out.
   */
  private async _handleLogoutClicked(event: MouseEvent, token?: string): Promise<void> {
    if (this._copyAuthTokenToClipboardTriggered(event, token)) {
      console.log("Authentication token copied to clipboard.");
      copyToClipboard(`${HEADER_BEARER} ${token}`);
      return;
    } else if (this._showLogoutOptionsTriggered(event)) {
      return this._showLogoutOptions();
    } else {
      return this._performLogout();
    }
  }

  /**
   * Displays logout options via notifications.
   */
  private _showLogoutOptions(): void {
    this._notificationsService.post({
      message: this._translateService.instant(_TRANSLATE_TITLE_KEY),
      showCloseButton: true,
      actions: {
        clearCache: {
          label: this._translateService.instant(_TRANSLATE_CLEAR_CACHE_KEY),
          action: async () => this._clearAuthenticationAndLogout()
        },
        clearAll: {
          label: this._translateService.instant(_TRANSLATE_CLEAR_ALL_DATA_KEY),
          action: async () => this._clearLocalStorageAndLogout()
        }
      }
    });
  }

  /**
   * Returns true if shift+alt are held and a token exists.
   */
  private _copyAuthTokenToClipboardTriggered(event: MouseEvent, token?: string): boolean {
    return !!(event.shiftKey && event.altKey && token);
  }

  /**
   * Returns true if only shift is held.
   */
  private _showLogoutOptionsTriggered(event: MouseEvent): boolean {
    return !!(event.shiftKey);
  }

  /**
   * Clears authentication data (from store/localStorage) and forces a reload.
   */
  private async _clearAuthenticationAndLogout(): Promise<void> {
    await this._performLogout();
    this._reloadPage(true);
  }

  /**
   * Clears all local storage and forces a reload.
   */
  private async _clearLocalStorageAndLogout(): Promise<void> {
    await this._performLogout();
    localStorage.clear();
    this._reloadPage(true);
  }

  /**
   * Forces a full page reload.
   */
  private _reloadPage(withCacheBuster: boolean = false) {
    // Create a URL object from the current location.
    var url = new URL(window.location.href);
    if (true == withCacheBuster) {
      // Set or update the "cacheBuster" parameter to the current timestamp.
      url.searchParams.set("cacheBuster", new Date().getTime().toString());
    }
    // Redirect the browser to the new URL.
    window.location.href = url.toString();
  }

  public authenticationToken() {
    return null != this._authSPI ? this._authSPI.authenticationToken() : null;
  }

  public isTokenExpired(): boolean {
    return null != this._authSPI ? this._authSPI.isTokenExpired() : true;
  }

  public storeUserContext(userContext: fromUser.UserContext) {
    localStorage.setItem(LS_USER, JSON.stringify(userContext));
  }

}

export {
  AUTH_PICTURE,
  AUTH_USER_NAME
};
