import { Store } from "@ngrx/store";
import gql from "graphql-tag";
import * as jp from "jsonpath";
import { BusinessDateRange } from "../../model/business-dates/business-date-range.model";
import { BusinessDate } from "../../model/business-dates/business-date.model";
import { TemporalAggregation } from "../../model/business-dates/temporal-aggregation.enum";
import { DataService } from "../data/data.service";
import * as i0 from "@angular/core";
import * as i1 from "../data/data.service";
import * as i2 from "@ngrx/store";
const _JSON_PATH_CURRENT_BUSINESS_DATES = "$..CurrentBusinessDates";
const _JSON_PATH_CURRENT_BUSINESS_DATE_RANGES = "$..BusinessDateRanges[*]";
const _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES = "$..HydratedBusinessDateRanges[*]";
const _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES_BY_CALENDAR_DATETIME = "$..HydratedBusinessDateRangesByCalendarDateTime[*]";
const _JSON_PATH_BUSINESS_DATE_OFFSET = "$..OffsetBusinessDate";
const _CURRENT_BUSINESS_DATES_QUERY = gql `
query currentBusinessDates($temporalAggregations: [TemporalAggregationEnum!]!) {
  GroupEntity {
    guid
    CurrentBusinessDates(TemporalAggregations: $temporalAggregations) {
      ..._businessDateDetails
    }
  }
}

fragment _businessDateDetails on BusinessDate {
  guid
  type
  year
  quarter
  month
  week
  day
  Event {
    ..._eventDetails
  }
  CalendarDateRange {
    From {
      ..._calendarDetails
    }
    To {
      ..._calendarDetails
    }
  }
}

fragment _eventDetails on Event {
  guid
  id
  nameRef
  year
  quarter
  month
  week
  day
}

fragment _calendarDetails on DateTime {
  year
  month
  day
  asString
}

`;
// tslint:disable:max-line-length
const _HYDRATE_BUSINESS_DATE_RANGE_QUERY = gql `
query hydrateBusinessDateRanges($businessDateRange: BusinessDateRangeInput!, $baselineBusinessDate: BusinessDateInput, $temporalAggregations: [TemporalAggregationEnum!]) {
  GroupEntity {
    guid
    Periods {
      HydratedBusinessDateRanges(BusinessDateRange: $businessDateRange, BaselineBusinessDate: $baselineBusinessDate, TemporalAggregations: $temporalAggregations) {
        Last {
          ..._offsetDetails
        }
        Next {
          ..._offsetDetails
        }
        From {
          ..._rangeDetails
        }
        To {
          ..._rangeDetails
        }
      }
    }
  }
}

fragment _rangeDetails on BusinessDate {
  guid
  type
  year
  quarter
  month
  week
  day
  Event {
    ..._eventDetails
  }
  CalendarDateRange {
    From {
      ..._calendarDetails
    }
    To {
      ..._calendarDetails
    }
  }
}

fragment _eventDetails on Event {
  guid
  id
  nameRef
  year
  quarter
  month
  week
  day
}

fragment _calendarDetails on DateTime {
  year
  month
  day
  asString
}

fragment _offsetDetails on BusinessDateOffset {
  years
  quarters
  months
  weeks
  days
}
`;
// tslint:disable:max-line-length
const _BUSINESS_DATE_OFFSET_QUERY = gql `
query businessDateOffset($businessDate: BusinessDateInput!, $offset: BusinessDateOffsetInput!, $temporalAggregation: TemporalAggregationEnum!, $direction: DirectionEnum!) {
  GroupEntity {
    guid
    Periods {
      OffsetBusinessDate(BusinessDate: $businessDate, Offset: $offset, TemporalAggregation: $temporalAggregation, Direction: $direction) {
        guid
        year
        quarter
        month
        week
        day
      }
    }
  }
}
`;
// tslint:enable:max-line-length
// tslint:disable:max-line-length
const _HYDRATE_CALENDAR_DATETIME = gql `
query hydrateCalendarDatetime($CalendarDateTime: DateTimeInput!, $temporalAggregations: [TemporalAggregationEnum!]) {
  GroupEntity {
    guid
    Periods {
      HydratedBusinessDateRangesByCalendarDateTime(CalendarDateTime: $CalendarDateTime, TemporalAggregations: $temporalAggregations) {
        From {
          ..._rangeDetailsCalendar
        }
        To {
          ..._rangeDetailsCalendar
        }
      }
    }
  }
}

fragment _rangeDetailsCalendar on BusinessDate {
  guid
  type
  year
  quarter
  month
  week
  day
  CalendarDateRange {
    From {
      ..._calendarDetails
    }
    To {
      ..._calendarDetails
    }
  }
}

fragment _calendarDetails on DateTime {
  year
  month
  day
  asString
}
`;
export class TemporalService {
    constructor(_dataService, _store) {
        this._dataService = _dataService;
        _store.select("temporal").subscribe((newContext) => {
            if (null != newContext) {
                this._latestTemporalContext = newContext;
            }
        });
    }
    async HydratedBusinessDateRanges(businessDateRange, temporalAggregations, baselineBusinessDate, convertToAbsolute = false) {
        baselineBusinessDate = baselineBusinessDate != null
            ? baselineBusinessDate
            : this._latestTemporalContext != null
                ? this._latestTemporalContext.current
                : null;
        baselineBusinessDate = BusinessDate.ToValidInputObject(baselineBusinessDate);
        businessDateRange = BusinessDateRange.ToValidInputObject(businessDateRange);
        const queryOptions = {
            query: _HYDRATE_BUSINESS_DATE_RANGE_QUERY,
            variables: {
                businessDateRange,
                temporalAggregations,
                baselineBusinessDate
            },
        };
        const response = await this._dataService.runQuery("TemporalService.HydratedBusinessDateRange", queryOptions);
        const businessDateRangesData = jp.query(response.data, _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES);
        return this._processBusinessDateRanges(businessDateRangesData, convertToAbsolute);
    }
    async businessDateOffset(businessDate, offset, temporalAggregation, direction) {
        const queryOptions = {
            query: _BUSINESS_DATE_OFFSET_QUERY,
            variables: {
                businessDate: BusinessDate.ToValidInputObject(businessDate),
                offset,
                temporalAggregation,
                direction
            }
        };
        const response = await this._dataService.runQuery("TemporalService.businessDateOffset", queryOptions);
        const businessDateRangesOffsetData = jp.query(response.data, _JSON_PATH_BUSINESS_DATE_OFFSET)[0];
        return businessDateRangesOffsetData;
    }
    async HydratedCalendarTimestamp(CalendarDateTime, temporalAggregations) {
        const queryOptions = {
            query: _HYDRATE_CALENDAR_DATETIME,
            variables: {
                CalendarDateTime,
                temporalAggregations
            },
        };
        const response = await this._dataService.runQuery("TemporalService.HydratedBusinessDateRange", queryOptions);
        const businessDateRangesData = jp.query(response.data, _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES_BY_CALENDAR_DATETIME);
        return this._processBusinessDateRanges(businessDateRangesData, true);
    }
    async HydrateInitialBaselineDatesAndRanges(businessDateRange, contextTemporalAggregation, hydrateTemporalAggregations, L4L) {
        businessDateRange = BusinessDateRange.ToValidInputObject(businessDateRange);
        const queryOptions = {
            query: _CURRENT_BUSINESS_DATES_QUERY,
            variables: {
                businessDateRange,
                L4L,
                temporalAggregations: hydrateTemporalAggregations
            }
        };
        const response = await this._dataService.runQuery("TemporalService.HydrateInitialBaselineDatesAndRanges", queryOptions);
        const currentByType = this._processCurrentBusinessDates(jp.query(response.data, _JSON_PATH_CURRENT_BUSINESS_DATES)[0]);
        // Hydrate business date ranges using the Day current business date as the baseline.
        const rangesByType = await this.HydratedBusinessDateRanges(businessDateRange, hydrateTemporalAggregations, currentByType[TemporalAggregation.Day], false);
        const newContext = {
            aggregation: contextTemporalAggregation,
            current: currentByType[TemporalAggregation.Day],
            currentByType: currentByType,
            range: rangesByType[contextTemporalAggregation],
            rangesByType: rangesByType,
            L4L: L4L
        };
        return newContext;
    }
    _processCurrentBusinessDates(currentBusinessDates) {
        return currentBusinessDates.reduce((dict, curr) => {
            const bd = BusinessDate.ToValidContextObject(curr);
            dict[bd.type] = bd;
            return dict;
        }, {});
    }
    _processBusinessDateRanges(businessDateRanges, convertToAbsolute) {
        return businessDateRanges.reduce((dict, curr) => {
            const bd = BusinessDateRange.ToValidContextObject(curr, convertToAbsolute);
            dict[bd.From.type] = bd;
            return dict;
        }, {});
    }
}
TemporalService.ɵfac = function TemporalService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TemporalService)(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.Store)); };
TemporalService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TemporalService, factory: TemporalService.ɵfac });
