import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { QueryOptions } from "@apollo/client";
import gql from "graphql-tag";
import * as jp from "jsonpath";

import * as fromContext from "../../context/context";
import * as fromTemporal from "../../context/temporal.reducers";
import {
  BusinessDateRange
} from "../../model/business-dates/business-date-range.model";
import {
  BusinessDate
} from "../../model/business-dates/business-date.model";
import {
  PeriodOnPeriodType,
  TemporalAggregation
} from "../../model/business-dates/temporal-aggregation.enum";
import {
  Timestamp
} from "../../model/timestamp.model";
import {
  DataService
} from "../data/data.service";

const _JSON_PATH_CURRENT_BUSINESS_DATES: string = "$..CurrentBusinessDates";
const _JSON_PATH_CURRENT_BUSINESS_DATE_RANGES: string  = "$..BusinessDateRanges[*]";
const _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES: string  = "$..HydratedBusinessDateRanges[*]";
const _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES_BY_CALENDAR_DATETIME: string  = "$..HydratedBusinessDateRangesByCalendarDateTime[*]";
const _JSON_PATH_BUSINESS_DATE_OFFSET: string  = "$..OffsetBusinessDate";

const _CURRENT_BUSINESS_DATES_QUERY = gql`
query currentBusinessDates($temporalAggregations: [TemporalAggregationEnum!]!) {
  GroupEntity {
    guid
    CurrentBusinessDates(TemporalAggregations: $temporalAggregations) {
      ..._businessDateDetails
    }
  }
}

fragment _businessDateDetails on BusinessDate {
  guid
  type
  year
  quarter
  month
  week
  day
  Event {
    ..._eventDetails
  }
  CalendarDateRange {
    From {
      ..._calendarDetails
    }
    To {
      ..._calendarDetails
    }
  }
}

fragment _eventDetails on Event {
  guid
  id
  nameRef
  year
  quarter
  month
  week
  day
}

fragment _calendarDetails on DateTime {
  year
  month
  day
  asString
}

`;

// tslint:disable:max-line-length
const _HYDRATE_BUSINESS_DATE_RANGE_QUERY = gql`
query hydrateBusinessDateRanges($businessDateRange: BusinessDateRangeInput!, $baselineBusinessDate: BusinessDateInput, $temporalAggregations: [TemporalAggregationEnum!]) {
  GroupEntity {
    guid
    Periods {
      HydratedBusinessDateRanges(BusinessDateRange: $businessDateRange, BaselineBusinessDate: $baselineBusinessDate, TemporalAggregations: $temporalAggregations) {
        Last {
          ..._offsetDetails
        }
        Next {
          ..._offsetDetails
        }
        From {
          ..._rangeDetails
        }
        To {
          ..._rangeDetails
        }
      }
    }
  }
}

fragment _rangeDetails on BusinessDate {
  guid
  type
  year
  quarter
  month
  week
  day
  Event {
    ..._eventDetails
  }
  CalendarDateRange {
    From {
      ..._calendarDetails
    }
    To {
      ..._calendarDetails
    }
  }
}

fragment _eventDetails on Event {
  guid
  id
  nameRef
  year
  quarter
  month
  week
  day
}

fragment _calendarDetails on DateTime {
  year
  month
  day
  asString
}

fragment _offsetDetails on BusinessDateOffset {
  years
  quarters
  months
  weeks
  days
}
`;

// tslint:disable:max-line-length
const _BUSINESS_DATE_OFFSET_QUERY = gql`
query businessDateOffset($businessDate: BusinessDateInput!, $offset: BusinessDateOffsetInput!, $temporalAggregation: TemporalAggregationEnum!, $direction: DirectionEnum!) {
  GroupEntity {
    guid
    Periods {
      OffsetBusinessDate(BusinessDate: $businessDate, Offset: $offset, TemporalAggregation: $temporalAggregation, Direction: $direction) {
        guid
        year
        quarter
        month
        week
        day
      }
    }
  }
}
`;
// tslint:enable:max-line-length

// tslint:disable:max-line-length
const _HYDRATE_CALENDAR_DATETIME = gql`
query hydrateCalendarDatetime($CalendarDateTime: DateTimeInput!, $temporalAggregations: [TemporalAggregationEnum!]) {
  GroupEntity {
    guid
    Periods {
      HydratedBusinessDateRangesByCalendarDateTime(CalendarDateTime: $CalendarDateTime, TemporalAggregations: $temporalAggregations) {
        From {
          ..._rangeDetailsCalendar
        }
        To {
          ..._rangeDetailsCalendar
        }
      }
    }
  }
}

fragment _rangeDetailsCalendar on BusinessDate {
  guid
  type
  year
  quarter
  month
  week
  day
  CalendarDateRange {
    From {
      ..._calendarDetails
    }
    To {
      ..._calendarDetails
    }
  }
}

fragment _calendarDetails on DateTime {
  year
  month
  day
  asString
}
`;

@Injectable()
export class TemporalService {

  private _latestTemporalContext: fromTemporal.TemporalContext;
  public constructor(private _dataService: DataService, _store: Store<fromContext.Context>) {
    _store.select("temporal").subscribe((newContext: fromTemporal.TemporalContext) => {
      if (null != newContext) {
        this._latestTemporalContext = newContext;
      }
    });
  }

  public async HydratedBusinessDateRanges(
    businessDateRange: BusinessDateRange,
    temporalAggregations: TemporalAggregation[],
    baselineBusinessDate?: BusinessDate,
    convertToAbsolute: boolean = false
  ): Promise<fromTemporal.BusinessDateRangeDictionary> {

    baselineBusinessDate = baselineBusinessDate != null
      ? baselineBusinessDate
      : this._latestTemporalContext != null
        ? this._latestTemporalContext.current
        : null;

    baselineBusinessDate = BusinessDate.ToValidInputObject(baselineBusinessDate);
    businessDateRange = BusinessDateRange.ToValidInputObject(businessDateRange);

    const queryOptions: QueryOptions = {
      query: _HYDRATE_BUSINESS_DATE_RANGE_QUERY,
      variables: {
        businessDateRange,
        temporalAggregations,
        baselineBusinessDate
      },
    };
    const response = await this._dataService.runQuery("TemporalService.HydratedBusinessDateRange", queryOptions);

    const businessDateRangesData = jp.query(response.data, _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES);
    return this._processBusinessDateRanges(businessDateRangesData, convertToAbsolute);

  }

  public async businessDateOffset(
    businessDate: BusinessDate,
    offset: any,
    temporalAggregation: TemporalAggregation,
    direction: any
  ): Promise<any> {

    const queryOptions: QueryOptions = {
      query: _BUSINESS_DATE_OFFSET_QUERY,
      variables: {
        businessDate: BusinessDate.ToValidInputObject(businessDate),
        offset,
        temporalAggregation,
        direction
      }
    };
    const response = await this._dataService.runQuery("TemporalService.businessDateOffset", queryOptions);
    const businessDateRangesOffsetData = jp.query(response.data, _JSON_PATH_BUSINESS_DATE_OFFSET)[0];
    return businessDateRangesOffsetData;

  }

  public async HydratedCalendarTimestamp(
    CalendarDateTime: Timestamp,
    temporalAggregations: TemporalAggregation[]
  ): Promise<fromTemporal.BusinessDateRangeDictionary> {
    const queryOptions: QueryOptions = {
      query: _HYDRATE_CALENDAR_DATETIME,
      variables: {
        CalendarDateTime,
        temporalAggregations
      },
    };
    const response = await this._dataService.runQuery("TemporalService.HydratedBusinessDateRange", queryOptions);

    const businessDateRangesData = jp.query(response.data, _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES_BY_CALENDAR_DATETIME);
    return this._processBusinessDateRanges(businessDateRangesData, true);

  }

  public async HydrateInitialBaselineDatesAndRanges(
    businessDateRange: BusinessDateRange,
    contextTemporalAggregation: TemporalAggregation,
    hydrateTemporalAggregations: TemporalAggregation[],
    L4L?: PeriodOnPeriodType
  ): Promise<fromTemporal.TemporalContext> {

    businessDateRange = BusinessDateRange.ToValidInputObject(businessDateRange);
    const queryOptions: QueryOptions = {
      query: _CURRENT_BUSINESS_DATES_QUERY,
      variables: {
        businessDateRange,
        L4L,
        temporalAggregations: hydrateTemporalAggregations
      }
    };

    const response = await this._dataService.runQuery("TemporalService.HydrateInitialBaselineDatesAndRanges", queryOptions);
    const currentByType: fromTemporal.CurrentBusinessDateDictionary = this._processCurrentBusinessDates(jp.query(response.data, _JSON_PATH_CURRENT_BUSINESS_DATES)[0]);

    // Hydrate business date ranges using the Day current business date as the baseline.
    const rangesByType = await this.HydratedBusinessDateRanges(businessDateRange, hydrateTemporalAggregations, currentByType[TemporalAggregation.Day], false)
    const newContext: any = {
      aggregation: contextTemporalAggregation,
      current: currentByType[TemporalAggregation.Day],
      currentByType: currentByType,
      range: rangesByType[contextTemporalAggregation],
      rangesByType: rangesByType,
      L4L: L4L
    };
    return newContext;

  }

  private _processCurrentBusinessDates(currentBusinessDates: any): fromTemporal.CurrentBusinessDateDictionary {
    return currentBusinessDates.reduce((dict: any, curr: any) => {
      const bd = BusinessDate.ToValidContextObject(curr);
      dict[bd.type] = bd;
      return dict;
    }, {});
  }


  private _processBusinessDateRanges(businessDateRanges: any, convertToAbsolute: boolean): fromTemporal.BusinessDateRangeDictionary {
    return businessDateRanges.reduce((dict: any, curr: any) => {
      const bd = BusinessDateRange.ToValidContextObject(curr, convertToAbsolute);
      dict[bd.From.type] = bd;
      return dict;
    }, {});
  }

}
