import { Auth0Client, Auth0ClientOptions, createAuth0Client } from "@auth0/auth0-spa-js";
import * as fromUser from "../../../context/user.reducers";
import { formattedTimeString } from "../../../util/utils";
import { LoggingService } from "../../logging/logging.service";
import { AuthConfiguration } from "../auth-configuration.model";
import { AUTH_AUTHORIZATION, AUTHENTICATION_LOG_NAMESPACE, AuthResult, DIDICI_NAMESPACE, UserProfileResult } from "./auth-spi.interface";
import { BaseAuthSPI } from "./base-auth-spi";

export const PROVIDER_AUTH0 = "auth0"

const _DIDICI_API = DIDICI_NAMESPACE + "api";
const _AUTH0_LS_PREFIX = "@@auth0spajs@@::";
const _LOG_NAMESPACE = AUTHENTICATION_LOG_NAMESPACE + `.${PROVIDER_AUTH0}`;

export class Auth0AuthenticationServiceProvider extends BaseAuthSPI{

  private _auth0Client: Auth0Client;

  public constructor(loggingService: LoggingService) {
    super(loggingService, _LOG_NAMESPACE);
  }


  public async init(authConfig: AuthConfiguration) {
    this._logger.warn(formattedTimeString() + " Initializing Auth0 Universal Login Authentication Service Provider...");

    const clientOptions: Auth0ClientOptions = {
      domain: authConfig.domain,
      clientId: authConfig.ulClientID,
      cacheLocation: "localstorage", // Persist tokens across refreshes if needed
      useRefreshTokens: true,
      authorizationParams: {
        redirect_uri: window.location.origin + authConfig.callbackURL,
        scope: authConfig.scope,
        audience: _DIDICI_API
      }
    };

    this._auth0Client = await createAuth0Client(clientOptions);

    // Check if returning from an Auth0 redirect (code & state present)
    if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
      try {
        await this._auth0Client.handleRedirectCallback();
        // Remove query parameters from URL
        window.history.replaceState({}, document.title, window.location.pathname);
        const auth = await this.authDetails();
        this.authToLocalStorage(auth);
        this._logger.warn("Handled Auth0 redirect callback successfully.");
      } catch (error) {
        this._logger.error("Error handling Auth0 redirect callback", error);
      }
    }

  }

  public async authDetails(): Promise<fromUser.Auth> {
    try {
      // Retrieve tokens and user profile.
      const accessToken = await this._auth0Client.getTokenSilently();
      const userProfile = await this._auth0Client.getUser();

      // Construct your Auth object.
      const authResult: fromUser.Auth= {
        provider: PROVIDER_AUTH0,
        isAuthenticated: true,
        accessToken,
        authorization: this.typedAuthorization(userProfile?.[AUTH_AUTHORIZATION])
      };

      // Store authentication result.
      this.authToLocalStorage(authResult);
      this._logger.info("User is already authenticated", userProfile);
      return authResult;
    } catch (error) {
      this._logger.error("Error during silent authentication", error);
      return { isAuthenticated: false };
    }
  }

  public async authenticate(): Promise<AuthResult> {

    const isUserAuthenticated = await this._isAuthenticated();
    if (!isUserAuthenticated) {
      this._logger.warn("User is not authenticated. Redirecting to Universal Login.");
      await this._auth0Client.loginWithRedirect({
        appState: { target: window.location.pathname }
      });
      // The redirect will occur; this promise may not resolve until the user returns.
      return { isError: false };
    } else {
      this._logger.debug("User is authenticated.  Proceeding.");
      const auth = await this.authDetails();
      return {
        isError: false,
        error: null,
        auth: auth
      }
    }
  }

  /**
   * Retrieves the user profile.
   */
  public async userProfile(): Promise<UserProfileResult> {
    try {
      const userProfile = await this._auth0Client.getUser();
      this._logger.debug("Retrieved user profile:", userProfile);
      return {
        isError: false,
        userProfile,
        authorization: this.typedAuthorization(userProfile?.[AUTH_AUTHORIZATION])
      };
    } catch (error) {
      const errorMessage = "There was an error retrieving profile data.";
      this._logger.error(errorMessage, error);
      return {
        isError: true,
        error: errorMessage + error.toString()
      };
    }
  }

  public async logout() {
    this._cleanupAuthenticationProviderLocalStorage();
  }



  private _cleanupAuthenticationProviderLocalStorage() {
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(_AUTH0_LS_PREFIX)) {
        localStorage.removeItem(key);
      }
    });

  }

  /**
   * Checks whether the user is authenticated.
   */
  private async _isAuthenticated(): Promise<boolean> {
    const auth = this.authFromLocalStorage();
    if (null == auth) {
      return false;
    }
    try {
      const token = await this._auth0Client.getTokenSilently();
      return true;
    } catch (error) {
      console.log("Got this back when trying to get token silently", error);
      return false;
    }
  }

  protected _cleanUpAuthData():void {

    const ret =  this.authFromLocalStorage();
    if (null != ret) {
      if (PROVIDER_AUTH0 !== ret.provider) {
        this._logger.warn(formattedTimeString()+ " The stored authentication is for a different provider.  Removing.");
        this.authToLocalStorage(null);
      }
    }
  }

}
